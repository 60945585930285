
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import React from 'react';
import Quiz from './components/Quiz';

const App = () => {
  return (
    <div className="app">
      <video autoPlay loop muted className="video-background">
        <source src="bg-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className='quiz_wrapper'>
        <Quiz />
      </div>
    </div>
  );
};

export default App;
