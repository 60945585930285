import React, { useState } from 'react';

const questions = [
  {
    id: 1,
    difficulty: 1,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q1img2.png", isCorrect: true },
      { id: 2, image: "q1img1.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 2,
    difficulty: 2,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q2img2.png", isCorrect: false },
      { id: 2, image: "q2img1.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 3,
    difficulty: 3,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q3img2.png", isCorrect: false },
      { id: 2, image: "q3img1.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 4,
    difficulty: 4,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q4img1.png", isCorrect: true },
      { id: 2, image: "q4img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 5,
    difficulty: 5,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q5img1.png", isCorrect: true },
      { id: 2, image: "q5img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 6,
    difficulty: 6,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q6img1.png", isCorrect: false },
      { id: 2, image: "q6img2.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 7,
    difficulty: 7,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q7img1.png", isCorrect: true },
      { id: 2, image: "q7img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 8,
    difficulty: 8,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q8img1.png", isCorrect: true },
      { id: 2, image: "q8img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 9,
    difficulty: 9,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q9img1.png", isCorrect: true },
      { id: 2, image: "q9img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 10,
    difficulty: 10,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q10img1.png", isCorrect: false },
      { id: 2, image: "q10img2.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 11,
    difficulty: 11,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q11img1.png", isCorrect: false },
      { id: 2, image: "q11img2.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 12,
    difficulty: 12,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q12img1.png", isCorrect: true },
      { id: 2, image: "q12img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 13,
    difficulty: 13,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q13img2.png", isCorrect: true },
      { id: 2, image: "q13img1.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 14,
    difficulty: 14,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q14img1.png", isCorrect: true },
      { id: 2, image: "q14img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 15,
    difficulty: 15,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q15img1.png", isCorrect: false },
      { id: 2, image: "q15img2.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 16,
    difficulty: 16,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q16img1.png", isCorrect: true },
      { id: 2, image: "q16img2.png", isCorrect: false }
    ],
    correctAnswerIndex: 0,
  },
  {
    id: 17,
    difficulty: 17,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q17img1.png", isCorrect: false },
      { id: 2, image: "q17img2.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  },
  {
    id: 18,
    difficulty: 18,
    question: "Select the design that is more correct",
    answers: [
      { id: 1, image: "q18img1.png", isCorrect: false },
      { id: 2, image: "q18img2.png", isCorrect: true }
    ],
    correctAnswerIndex: 1,
  }
];

const Quiz = () => {

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [showForm, setShowForm] = useState(false); // Track if form should be shown
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedCorrectAnswer, setSelectedCorrectAnswer] = useState(null);
  const [selectedWrongAnswer, setSelectedWrongAnswer] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const [showComparison, setShowComparison] = useState(false);
  const [questionHidden, setQuestionHidden] = useState(false); // New state to hide the question
  const [isDemoClassActive, setIsDemoClassActive] = useState(false);
  const [wrongMessageClass, setWrongMessageClass] = useState(""); // New state for wrong message class
  const totalQuestions = questions.length;
  const [compareActive, setCompareActive] = useState(false);

  // useEffect(() => {
  //   // Retrieve the last question index, or start from 0
  //   const lastQuestionIndex = parseInt(localStorage.getItem('currentQuestionIndex')) || 0;
  //   const nextQuestionIndex = (lastQuestionIndex + 1) % totalQuestions;

  //   setCurrentQuestionIndex(nextQuestionIndex);
  //   localStorage.setItem('currentQuestionIndex', nextQuestionIndex);
  // }, []);

  const handleAnswerClick = (answer) => {
    if (!selectedCorrectAnswer && !selectedWrongAnswer) {
      if (answer.isCorrect) {
        setSelectedCorrectAnswer(answer);
      } else {
        setSelectedWrongAnswer(answer);
        setWrongMessageClass("new-wrong"); // Add this line to update the class for wrong answers
      }
      // Show both Compare and Next buttons after an answer is selected    
      setShowButtons(true); // Show both buttons after answer is clicked
      setShowButtons(true);
      setQuestionHidden(true); // Hide the question after an answer is selected
    }
    
  };

  const handleCompareClick = () => {
    // Toggle the demo class on each Compare button click
    setIsDemoClassActive(prevState => !prevState); // Toggle the class state
    setShowComparison(true); // Show comparison view
    setCompareActive(true); // Activate compare class
};


const handleNextClick = () => {
    if (selectedCorrectAnswer) {
      setScore(prevScore => prevScore + 10);
    }

    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < totalQuestions) {
      setCurrentQuestionIndex(nextIndex);
    } else {
      setQuizFinished(true);
      setShowForm(true); // Show form after quiz ends
    }

    setSelectedCorrectAnswer(null);
    setSelectedWrongAnswer(null);
    setShowComparison(false);
    setShowButtons(false);
    setQuestionHidden(false); // Show the question again for the next question
    setIsDemoClassActive(false); // Remove demo class when next button is clicked
};


  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowForm(false); // Hide form after submission
  };

  const restartQuiz = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setQuizFinished(false);
    setSelectedCorrectAnswer(null);
    setSelectedWrongAnswer(null);
    setShowButtons(false);
    setShowComparison(false);
    setQuestionHidden(false); // Reset the question visibility
    setName('');
    setEmail('');
    setShowForm(false); // Reset to hide the form initially
  };

  if (showForm) {
    return (
      <div className="quiz-container">
        <div className='form-wrapper'>
        <h3>Enter Your Details to View Your Score</h3>
        <form onSubmit={handleFormSubmit} className="player-form">
          <label>
            Name:&nbsp;
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <button type="submit" className="btn btn-info text-white">
            View Score
          </button>
        </form>
        </div>
      </div>
    );
  }


  if (quizFinished && !showForm) {
    return (
      <div className={`quiz-container ${isDemoClassActive ? 'demo' : ''}`}>
        <div className='leaderboard-wrapper'>
        <h2>Leaderboard</h2>
        <div className="leaderboard">
          <div className="leaderboard-entry">
            <span className="position">1</span>
            <span className="name"> {name || "Player"} </span>
            <span className="score">&nbsp; Score: {score} </span>
          </div>
          <div className="leaderboard-entry">
            <span className="position">2</span>
            <span className="name">Akash</span>
            <span className="score">&nbsp; Score: {score - 10}</span>
          </div>
          <div className="leaderboard-entry">
            <span className="position">3</span>
            <span className="name">Manju</span>
            <span className="score">&nbsp; Score: {score - 20}</span>
          </div>
        </div>
        <button onClick={restartQuiz} className="btn btn-info text-white">
          Play Again
        </button>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className={`quiz-container ${isDemoClassActive ? 'demo' : ''}`}>
      <div className="uxdt_header">
          <div className="container">
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="uxdtlogo"><img src="uxdt-logo.svg"/> <span>Games</span> </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="uxdtcontact">Contact us</div>
            </div>
            <div className='col-lg-12 custom-mrgn'>
              <div className="score-display">
                <img src="Coins.svg"/>
              <span>00{score}</span>
              </div>
            </div>
          </div>
          </div>
        </div>
      {/* Conditionally render the question */}
      {!questionHidden && <h3>{currentQuestion.question}</h3>}

      <div className="answer-list">
        {currentQuestion.answers.map((answer) => (
          <div
            key={answer.id}
            className={`css-12ogqma ${
              selectedCorrectAnswer
                ? answer.isCorrect
                  ? `selected-answer correct-${currentQuestion.id}`
                  : `selected-answer wrong-${currentQuestion.id} z`
                : selectedWrongAnswer
                ? answer.isCorrect
                  ? `selected-answer correct-${currentQuestion.id} ${compareActive ? 'compare-active' : ''}`
                  : `selected-answer wrong-${currentQuestion.id} ${wrongMessageClass}`
                : ''
            }`}
            onClick={() => handleAnswerClick(answer)}
          >
            <img 
              className="css-v2kfba img-fluid"
              src={answer.image} 
              alt={answer.text ? answer.text : 'Answer option image'} 
            />

            {/* Show feedback for correct answers */}
            {(selectedCorrectAnswer || selectedWrongAnswer) && answer.isCorrect && (
              <div className="feedback">
                <img
                  src="correct-icon.png"
                  alt="Correct Answer"
                  style={{ width: '50px', height: '50px' }}
                />
                
              </div>
            )}

            {/* Show feedback for incorrect answers */}
            {(selectedCorrectAnswer || selectedWrongAnswer) && !answer.isCorrect && (
              <div className="feedback">
                <img
                  src="wrong-icon.png"
                  alt="Wrong Answer"
                  style={{ width: '50px', height: '50px' }}
                />
                <div className={`dynamic-message ${wrongMessageClass}`}>
                  <span className="wrong-message">
                    {currentQuestion.difficulty === 1  
                      ? "Font and speech bubble padding"
                      : currentQuestion.difficulty === 2
                      ? "Font and colour of font"
                      : currentQuestion.difficulty === 3
                      ? "Speech bubble"
                      : currentQuestion.difficulty === 4
                      ? "Text box length"
                      : currentQuestion.difficulty === 5
                      ? "Error text placement"
                      : currentQuestion.difficulty === 6
                      ? "One button with default style"
                      : currentQuestion.difficulty === 7
                      ? "Font size in buttons"
                      : currentQuestion.difficulty === 8
                      ? "Subtitle text contrast"
                      : currentQuestion.difficulty === 9
                      ? "Capitalization"
                      : currentQuestion.difficulty === 10
                      ? "Activity indicator colour"
                      : currentQuestion.difficulty === 11
                      ? "Photo aspect ratio"
                      : currentQuestion.difficulty === 12
                      ? "Photo aspect ratio"
                      : currentQuestion.difficulty === 13
                      ? "Icon size"
                      : currentQuestion.difficulty === 14
                      ? "Consistent icons style"
                      : currentQuestion.difficulty === 15
                      ? "Placeholder text contrast"
                      : currentQuestion.difficulty === 16
                      ? "Button background contrast"
                      : currentQuestion.difficulty === 17
                      ? "Icons contrast"
                      : currentQuestion.difficulty === 18
                      ? "Consistent separation lines"
                      
                      : "Error message"
                    }
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

<div className='btn-wrapper'>
    {showButtons && (
          <div className='prev-next'>
            <button
              className="btn custom-bg"
              onClick={handleCompareClick}
            >
              Compare

              {showComparison}
              
            </button>

            <button
              className="btn custom-bg"
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        )}

<div className="progress-container">
    <progress
      value={currentQuestionIndex + 1}
      max={totalQuestions}
      className="progress-bar"
    ></progress>
    <p>{currentQuestionIndex + 1} / {totalQuestions}</p>
    <span>Easy</span>
</div>

</div>
      
 </div>
  );
};



export default Quiz;
